import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CropImage from '@common/src/common/ImageUpload/CropImage'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import { DEFAULT_BANNER_OPTIONS, DEFAULT_BANNER_TYPES, IMG_UPLOAD, STATUS_OPTIONS } from '@hub/constants/constants'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import PreviewImage from '@hub/components/common/ImageHolders/previewImage'
import { triggerBannerActions } from '@hub/service/firebase/company'
import { getStorage, ref as fbRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { timeStamptoDate } from '@common/src/common/helper'
import Dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const BannerForm = ({ id, data, isEdit, onComplete, onBannerTypeChange }) => {
    const toast = useToast()
    const webImageRef = useRef(null)
    const appImageRef = useRef(null)
    const { selectedCompany } = useSelector((state) => state.auth)
    const [isImage, setIsImage] = useState({ web: isEdit, app: isEdit })
    const [cropFiles, setCropFiles] = useState({ web: '', app: '' })
    const [initialValues, setInitialValues] = useState({})
    const [bannerTypeOptions, setBannerTypeOptions] = useState([]);
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);
    const [bannerTypeValueLabel, setBannerTypeValueLabel] = useState('Banner Type Value');
    const { user } = useSelector((state) => state.auth)
    const fetchLabel = async (value) => {
        const [type] = value.split('/');
        const options = await handleChange(type);
        return options.find((item) => item.value === value)?.label || '';
    };
    Dayjs.extend(utc)
    Dayjs.extend(timezone)


    const companyTimeZone = selectedCompany?.timezone
    const getInitialData = async () => {
        setInitialValues({
            description: data?.description || '',
            status: data?.status ? { value: data?.status, label: data?.status } : { value: 'ACTIVE', label: 'ACTIVE' },
            web: data?.web || '',
            app: data?.app || '',
            bannerType: data?.link ? { value: data?.link.split('/')[0], label: data.link.split('/')[0] } : '',
            link: data?.link
                ? { value: data.link, label: await fetchLabel(data.link) }
                : '',
            sortOrder: data?.sortOrder || '',
            startDate: timeStamptoDate(data?.startDate, companyTimeZone),
            endDate: timeStamptoDate(data?.endDate, companyTimeZone),
            isDefault: data?.isDefault ? { value: data?.isDefault, label: data?.isDefault ? 'TRUE' : 'FALSE' } : { value: false, label: 'FALSE' }
        })

    }
    useEffect(() => {
        getInitialData()
    }, [])

    const validationSchema = Yup.object({
        startDate: Yup.date().required('Start date is required'),
        endDate: Yup.date().required('End date is required'),
        sortOrder: Yup.number().required('Sort Order is required').min(1, 'Sort order must be greater than zero'),
        web: !isImage.web && Yup.mixed()
            .test('web', 'Web app banner is required', (value) => value?.name !== undefined)
            .test('web', 'Format should be jpeg or png or jpg', (value) =>
                !value ||
                IMG_UPLOAD.BANNER.WEB.FORMATS.includes(value?.type?.split('/')[1])
            )
            .test('web', `Size should be maximum ${IMG_UPLOAD.BANNER?.WEB?.MAX_SIZE}`, (value) =>
                !value ||
                value.size <= 1024 * 1024
            )
            .required('Web app banner is required'),
        app: !isImage.app && Yup.mixed()
            .test('app', 'App banner is required', (value) => value !== undefined)
            .test('app', 'Format should be jpeg or png or jpg', (value) =>
                !value ||
                IMG_UPLOAD.BANNER.APP.FORMATS.includes(value?.type?.split('/')[1])
            )
            .test('app', `Size should be maximum ${IMG_UPLOAD.BANNER?.APP?.MAX_SIZE}`, (value) =>
                !value ||
                value.size <= 1024 * 1024
            )
            .required('App banner is required'),
        bannerType: Yup.mixed().required('Banner Type Required'),
        link: Yup.mixed().required('Banner Type Value Required'),
    })

    const handleImageUpload = async (file, type) => {
        // Initialize Firebase storage
        const storage = getStorage()
        // Generate a unique string for the file name
        const randomString = Date.now()
        // Construct the file path
        let path = `company/${selectedCompany?.value}/banner/${type}_bnr_`
        const storageRef = fbRef(storage, `${path}_${randomString}.${file.path.split('.').pop()}`)

        // Upload the file to Firebase storage
        const uploadTask = uploadBytes(storageRef, cropFiles[type])

        try {
            // Wait for the upload to complete and get the download URL
            await uploadTask
            const url = await getDownloadURL(storageRef)
            return url
        } catch (error) {
            // Log any errors that occur during upload
            console.log('error', error)
            return null
        }
    }
    const getFormData = (values) => {
        const formData = isEdit ? { id: data.id } : {}

        if (values.startDate !== initialValues.startDate) {
            formData.startDate = Dayjs.tz(values.startDate, companyTimeZone)
        }

        if (values.endDate !== initialValues.endDate) {
            formData.endDate = Dayjs.tz(values.endDate, companyTimeZone)
        }

        if (values.sortOrder !== initialValues.sortOrder) {
            formData.sortOrder = parseInt(values.sortOrder)
        }

        if (values.description !== initialValues.description) {
            formData.description = values.description
        }
        formData.isDefault = values.isDefault.value
        formData.companyId = String(selectedCompany.value)
        formData.status = values.status.value
        formData[isEdit ? 'updatedBy' : 'createdBy'] = user?.uid

        if (values.link !== initialValues.link) {
            formData.link = values.link.value
        }

        return formData
    }
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true); // mark form submission as in progress

        // Prepare form data
        const formData = getFormData(values)
        
        // Handle web image upload if present
        if (webImageRef.current) {
            const webUrl = await handleImageUpload(values.web, 'web');
            formData.web = webUrl;
        }

        // Handle app image upload if present
        if (appImageRef.current) {
            const appUrl = await handleImageUpload(values.app, 'app');
            formData.app = appUrl;
        }

        try {
            // Trigger banner actions (add/update)
            await triggerBannerActions(formData, isEdit);
            toast.success(isEdit ? 'Banner updated successfully!' : 'Banner added successfully!');
            resetForm(); // reset the form after successful submission
            setSubmitting(false); // mark form submission as complete
            onComplete?.(); // invoke onComplete callback if available
        } catch (error) {
            // Handle errors during submission
            toast.error(isEdit ? 'Error updating banner.' : 'Error creating banner.');
            setSubmitting(false); // mark form submission as complete
        }
    };

    const handleChange = async (e) => {
        try {
            let optionArray = [];
            setBannerTypeOptions([]); // Clear previous options
            if (e === 'CATEGORY' && categoryTypeOptions.length > 0) {
                optionArray = categoryTypeOptions;
            }
            else if (e === 'PRODUCT' && productTypeOptions.length > 0) {
                optionArray = productTypeOptions;
            }
            else {
                const dataArray = await onBannerTypeChange(e); // Fetch new options
                if (dataArray) {
                    if (e === 'CATEGORY') {
                        setBannerTypeValueLabel('Banner Type - Category');
                        setCategoryTypeOptions(dataArray)
                    }
                    else if (e === 'PRODUCT') {
                        setBannerTypeValueLabel('Banner Type - Product');
                        setProductTypeOptions(dataArray)
                    }
                    optionArray = dataArray
                }
            }
            setBannerTypeOptions(optionArray); // Update state with new options
            return optionArray;
        } catch (error) {
            console.error('Error in handling banner type change:', error);
        }
    };

    return (
        <OffCanvasForm
            enableReinitialization
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            okText={isEdit ? 'Update' : 'Add'}
            closeText={isEdit ? 'Close' : 'Cancel'}
            id={id}
            autoClose={false}
        >
            {({ setFieldValue, values, errors, touched, setFieldTouched }) => {
                return (
                    <div className="grid grid-cols-12 gap-6">
                        <div className="xl:col-span-12 col-span-12">
                            <div className="box-body add-products !p-0">
                                <div className="grid grid-cols-1 gap-6">
                                    <label className="form-label" htmlFor="webImage">
                                        Web Image<span style={{ color: 'red' }}>*</span>
                                    </label>
                                    {isImage.web ?
                                        <PreviewImage
                                            src={values.web}
                                            isRemovable={true}
                                            onRemove={() => {
                                                setFieldValue('web', '');
                                                setFieldTouched('web', true)
                                                setIsImage({ ...isImage, web: false })
                                            }}
                                        />
                                        : <CropImage
                                            value={values.web}
                                            onChange={(file) => setFieldValue('web', file)}
                                            setCropFile={(file) => setCropFiles((prev) => ({ ...prev, web: file }))}
                                            errorText={touched.web && errors.web ? errors.web : null}
                                            ref={webImageRef}
                                            onUploadSuccess={() => { }}
                                            path={`company/${selectedCompany.value}/banner/web_bnr_`}
                                            minCrop={IMG_UPLOAD.BANNER.WEB.MIN_RESOLUTION}
                                        />
                                    }
                                    <label className="form-label" htmlFor="appImage">
                                        App Image<span style={{ color: 'red' }}>*</span>
                                    </label>
                                    {isImage.app ?
                                        <PreviewImage
                                            src={values.app}
                                            isRemovable={true}
                                            onRemove={() => {
                                                setFieldValue('app', '');
                                                setFieldTouched('app', true)
                                                setIsImage({ ...isImage, app: false })
                                            }}
                                        />
                                        : <CropImage
                                            value={values.app}
                                            onChange={(file) => setFieldValue('app', file)}
                                            setCropFile={(file) => setCropFiles((prev) => ({ ...prev, app: file }))}
                                            errorText={touched?.app && errors.app ? errors.app : null}
                                            ref={appImageRef}
                                            onUploadSuccess={() => { }}
                                            path={`company/${selectedCompany.value}/banner/app_bnr_`}
                                            minCrop={IMG_UPLOAD.BANNER.APP.MIN_RESOLUTION}
                                        />

                                    }

                                    <div className="grid grid-cols-1 gap-6">
                                        <SelectBox
                                            name="bannerType"
                                            label="Banner Type"
                                            value={values.bannerType} // Set value from form state (e.g., 'CATEGORY')
                                            onChange={async (e) => {
                                                setFieldValue('bannerType', e); // Update form field value
                                                setFieldValue('bannerTypeValue', ''); // Update form field value
                                                await handleChange(e.value); // Fetch options based on selection
                                            }}
                                            id="bannerType"
                                            options={DEFAULT_BANNER_TYPES}
                                            errorText={touched.bannerType && errors.bannerType ? errors.bannerType : null}
                                            required
                                        />

                                    </div>
                                    <div className="grid grid-cols-1 gap-6">
                                        <SelectBox
                                            name="link"
                                            label={bannerTypeValueLabel}
                                            value={values.link} // Set value from form state (e.g., 'CATEGORY')
                                            onChange={(e) => { setFieldValue('link', e) }}
                                            id="link"
                                            options={bannerTypeOptions}
                                            errorText={touched.link && errors.link ? errors.link : null}
                                            required
                                        />

                                    </div>
                                    <div className="grid grid-cols-1 gap-6">
                                        <InputBox
                                            type="text"
                                            id="description"
                                            name="description"
                                            label="Description"
                                            placeholder="Enter Description"
                                            value={values.description}
                                            onChange={(e) => {
                                                const sanitizedValue = e.target.value.replace(/^\s+/, ''); // Remove leading spaces
                                                setFieldValue('description', sanitizedValue); // Update Formik's value
                                              }}
                                        />
                                    </div>

                                    <div className="grid grid-cols-1 gap-6">
                                        <InputBox
                                            type="number"
                                            name="sortOrder"
                                            label="Sort Order"
                                            placeholder="Enter Sort Order"
                                            value={values.sortOrder}
                                            onChange={(e) => setFieldValue('sortOrder', e.target.value)}
                                            id="sortOrder"
                                            required
                                            errorText={touched.sortOrder && errors.sortOrder ? errors.sortOrder : null}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-6">
                                        <div className="grid grid-cols-2 gap-6">
                                            <InputBox
                                                type="datetime-local"
                                                name="startDate"
                                                label="Start Date"
                                                placeholder="Enter Start Date"
                                                value={values.startDate}
                                                onChange={(e) => setFieldValue('startDate', e.target.value)}
                                                min={!isEdit && new Date().toISOString().split('T')[0] + 'T00:00'}
                                                errorText={touched.startDate && errors.startDate ? errors.startDate : null}
                                                id="startDate"
                                                required
                                            />
                                            <InputBox
                                                type="datetime-local"
                                                name="endDate"
                                                label="End Date"
                                                placeholder="Enter End Date"
                                                value={values.endDate}
                                                onChange={(e) => setFieldValue('endDate', e.target.value)}
                                                min={values.startDate}
                                                errorText={touched.endDate && errors.endDate ? errors.endDate : null}
                                                id="endDate"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 gap-6">
                                        <div className="grid grid-cols-2 gap-6">
                                            <SelectBox
                                                name="status"
                                                label="Status"
                                                value={values.status}
                                                onChange={(e) => { setFieldValue('status', e) }}
                                                id="status"
                                                options={STATUS_OPTIONS}
                                            />
                                            <SelectBox
                                                name="isDefault"
                                                label="Default"
                                                value={values?.isDefault}
                                                onChange={(e) => { setFieldValue('isDefault', e) }}
                                                id="isDefault"
                                                options={DEFAULT_BANNER_OPTIONS}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        </OffCanvasForm>
    )
}
BannerForm.propTypes = {
    data: PropTypes.exact({
        id: PropTypes.string.isRequired,
        description: PropTypes.string,
        status: PropTypes.string.isRequired,
        isDefault: PropTypes.string.isRequired,
        web: PropTypes.string.isRequired,
        app: PropTypes.string.isRequired,
        sortOrder: PropTypes.number.isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        endDate: PropTypes.instanceOf(Date).isRequired,
        updatedBy: PropTypes.string,
        updatedAt: PropTypes.string,
        createdAt: PropTypes.string,
        type: PropTypes.string,
        bannerType: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }),
    isEdit: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    id: PropTypes.string,
    onBannerTypeChange: PropTypes.func.isRequired,
}


export default BannerForm
